/**
 * Functional header menu functionality (responsive)
 */

vl.dressFHMenu;

(function () {

  var functionalheadermenus     = document.querySelectorAll('.js-functional-header-menu');
  var activeState               = 'functional-header__menu--open';

  vl.dressFHMenu = function(menu) {

    var toggle = menu.querySelector('.js-functional-header-menu__toggle');

    toggle.addEventListener('click', function(e){
      if(hasClass(menu, activeState))
        removeClass(menu, activeState);
      else
        addClass(menu, activeState);
    });
  };

  [].forEach.call(functionalheadermenus, function (element) {
    vl.dressFHMenu(element);
  });

})();
