/**
 * We assume that in a sticky element items with an anchor link should have a scrollspy functionality
 */

(function () {

  // find all #-links in sticky elements
  var elements = document.querySelectorAll('.js-sticky a[href^="#"]'),
      latestKnownScrollY = 0,
      ticking = false;


  // only add scrollspy if all content is loaded
  window.addEventListener('load', function() {
    
    // only on medium or up
    if (vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall') {
      return;
    }

    window.addEventListener('scroll', onScroll, false);
    //window.addEventListener('resize', refresh, false);

    onScroll();
  });

  function onScroll() {
    latestKnownScrollY = window.pageYOffset;
    requestTick();
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }

  function update() {
    ticking = false;
    var currentScrollY = latestKnownScrollY;

    [].forEach.call(elements, function(el) {
      checkScrollSpy(el);
    });
  }

  function checkScrollSpy (element) {
    var href = element.getAttribute('href');
    var target = document.querySelector(href);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if(target && target.getBoundingClientRect().top < h/2){
      setActiveItem(element);
    }
  }

  function setActiveItem(item) {
    var activeClass = 'js-scrollspy-active';
    var otherItems = document.getElementsByClassName(activeClass);
    [].forEach.call(otherItems, function(el) {
      if(item !== el){
        removeClass(el, activeClass);
      }
    });
    addClass(item, activeClass);
  }


})();
