/**
 * datatable functionalities
 */

vl.datatable = {};
vl.datatable.dress;

(function () {

  var tables              = document.querySelectorAll('[data-table]'),
      dataCheckedChkboxes = "[data-table-checkbox]:checked",
      dataTableChk        = "[data-table-checkbox]";
      dataActions         = "[data-table-action]";

    vl.datatable.dress = function(table) {

      var chkboxes                  = table.querySelectorAll(dataTableChk),
          mainChkbox                = table.querySelector('[data-table-check-all]'),
          dataRowSelectable         = table.querySelectorAll("[data-table-selectable]"),
          dataRowSelectableAnchors  = table.querySelectorAll("[data-table-selectable] a, [data-table-selectable] button, [data-table-selectable] input, [data-table-selectable] label");

      // Detect change in general checkbox
      if(mainChkbox !== null){
        mainChkbox.addEventListener('change', function(e){

          if(chkboxes !== null){
            [].forEach.call(chkboxes, function(chk){
              chk.checked = mainChkbox.checked;
            });
          }
        });
      }

      // Detect change in normal checkbox
      if(chkboxes !== null){
        [].forEach.call(chkboxes, function(chk){
          chk.addEventListener('change', function(e){
            toggleActions();
            if(!e.target.checked){
              if(mainChkbox !== null)
                mainChkbox.checked = false;
            }
          });
        });
      }

      // Detect clicks on data-table-selectable attribute
      [].forEach.call(dataRowSelectable, function(row){
        row.addEventListener('click', function(e){
          var chk = row.querySelector(dataTableChk);
          if(chk !== null){
            chk.checked = !chk.checked;
            toggleActions();
          }
        });
      });

      // Detect clicks on anchors, buttons, input elements and labels and stop propagation
      [].forEach.call(dataRowSelectableAnchors, function(anchor){
        anchor.addEventListener('click', function(e){
          if(e.stopPropagation)
            e.stopPropagation();
          else
            e.cancelBubble = true;
        });
      });

      /*
      *
      * toggleActions()
      *
      */
      function toggleActions(){
        var actions = table.querySelectorAll(dataActions);
        if(actions !== null){
          var checkedChkboxes = table.querySelectorAll(dataCheckedChkboxes);
          [].forEach.call(actions, function(action){
            if(checkedChkboxes.length){
              setActionsAttributes(action, true, false);
            }else{
              setActionsAttributes(action, false, true);
            }
          });
        }
      }

      /*
      *
      * setActionsAttributes()
      * action, dataShow, ariaHidden
      */
      function setActionsAttributes(action, dataShow, ariaHidden){
        action.setAttribute('data-show', dataShow);
        action.setAttribute('aria-hidden', ariaHidden);
      }
    };

    [].forEach.call(tables, function(table) {
      vl.datatable.dress(table);
    });

})();
