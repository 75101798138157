(function() {
  // initialize on pageLoad
  window.addEventListener('load', function() {
    // Call text clamping
    textOverflowClamp();
    // bind on resize event
    window.addEventListener('resize', textOverflowClamp);
  });

  var clampClass = 'js-clamped',
      noTriggerClass = 'js-clamp-useless';

  /**
   * Clamp text to the given number of lines
   */
  function textOverflowClamp(){
    // get all elements with a data-attribute data-clamp
    var elements = document.querySelectorAll('[data-clamp]');
    // apply clamping for all attributes
    [].forEach.call(elements, function(element) {
      var clampSize = parseInt(element.getAttribute("data-clamp"));
      if(clampSize <= 0){
        return;
      }
      // do the actual clamping
      clamp(element, clampSize);
      // check if there is actually content clamped, we need this to decide if the read-more button is usefull or not.
      if(element.hasAttribute('data-clamp-id')){
        // get the trigger
        var trigger = document.querySelector('[data-clamp-target="' + element.getAttribute('data-clamp-id') + '"]');
        // check if the trigger exists and has not already been bound
        if(trigger !== null && !hasClass(trigger, 'js-clamp-bound')){
          addClass(trigger, 'js-clamp-bound');
          // attach the event listener
          trigger.addEventListener('click', function(e){
            e.preventDefault();
            undoOverflowClamp(element, this);
          });
        }
        // show / hide the button if it is required
        if(hasClass(element, clampClass)){
          // the item is clamped so it can be expanded
          removeClass(trigger, noTriggerClass);
        }else{
          addClass(trigger, noTriggerClass);
        }
      }
    });
  }


  /**
   * Dress all "read more" buttons to be able to undo clamping
   */
  function undoOverflowClamp(element, trigger){
    // set raw text in the element
    var text = (element.textContent || element.innerText);
    element.textContent = text;
    // remove data-clamp attribute
    element.removeAttribute('data-clamp');
    element.removeAttribute('data-clamp-id');
    // remove clamped class
    removeClass(element, clampClass);
    // delete the button, it has become completely useless
    trigger.parentNode.removeChild(trigger);
  }
})();
