/**
 * Create a tag input by data-attribute
 */

vl.dressHeroNav;

(function () {

  var navigations = document.querySelectorAll('.js-hero-navigation');


  vl.dressHeroNav = function(nav) {

    var elements = nav.querySelectorAll('.js-hero-navigation-listitem');
    var links = nav.querySelectorAll('.js-hero-navigation-link');
    var backgrounds = nav.querySelectorAll('.js-hero-navigation-background');
    var defaultClass = 'js-hero-navigation-default-link';
    var activeslide;

    function changeSlide(){
      //Change background of slide
      [].forEach.call(backgrounds, function(bg) {
        removeClass(bg, 'hero-navigation__background--active');
      });
      var activebg = document.querySelectorAll('[data-hero-index="' + activeslide + '"]');
      addClass(activebg[0], 'hero-navigation__background--active');
    }

    [].forEach.call(links, function (element) {
      dressHeroNavLink(element);
    });

    // trigger default element
    var defaultLink = nav.getElementsByClassName(defaultClass)[0];
    if(defaultLink) {
      fireEvent(defaultLink, 'mouseenter');
    } else {
      // if no default given, select a random link
      var rand = Math.floor(Math.random() * links.length);
      fireEvent(links[rand], 'mouseenter');
    }

    function dressHeroNavLink(element) {

      element.addEventListener('mouseenter', activateSlide);
      element.addEventListener('focus', activateSlide);

      function activateSlide(event) {
        var listitem = event.target.parentNode;

        [].forEach.call(elements, function (el2) {
          removeClass(el2, 'hero-navigation__list__listitem--active');
        });

        var index = listitem.getAttribute("data-hero-index");
        activeslide = index;
        addClass(listitem, 'hero-navigation__list__listitem--active');

        changeSlide();
      }
    }
  };



  [].forEach.call(navigations, function (element) {
    vl.dressHeroNav(element);
  });

})();
