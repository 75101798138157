/**
 * Fix a range slider for the range element
 */
(function () {

  var elements = document.getElementsByClassName('js-range');

  [].forEach.call(elements, function (element) {
    var slider = element.getElementsByClassName('js-range__slider')[0],
        fromInput = element.getElementsByClassName('js-range__from')[0],
        toInput = element.getElementsByClassName('js-range__to')[0];
    noUiSlider.create(slider, {
      start: [fromInput.value, toInput.value],
      step: 1,
      connect: true,
      range: {
        'min': parseInt(fromInput.value),
        'max': parseInt(toInput.value)
      }
    });

    // When the slider value changes, update the input and span
    slider.noUiSlider.on('update', function( values, handle ) {
      fromInput.value = parseInt(values[0]);
      toInput.value = parseInt(values[1]);
    });

    fromInput.addEventListener('change', function(){
      slider.noUiSlider.set([this.value, null]);
    });

    toInput.addEventListener('change', function(){
      slider.noUiSlider.set([null, this.value]);
    });
  });

})();
