
/**
 * Show container when specific option is selected in select attribute
 */
(function () {

  var wrappers = document.querySelectorAll('[data-show-on-select-wrapper]');

  [].forEach.call(wrappers, function (soswrapper) {
    dressShowOnSelect(soswrapper);
  });

  function dressShowOnSelect(soswrapper) {

    // get all selects in wrapper
    var sosSelects = soswrapper.querySelectorAll('[data-show-on-select]');

    // Loop through selects in wrapper
    [].forEach.call(sosSelects, function(select){

      // detect change in select elem
      select.onchange = function(e){
        var dataId = select.getAttribute('data-id');
        var selectedOpt = select.options[select.selectedIndex];

        if(selectedOpt.hasAttribute('data-show-on-select-option')){
          resetContent(dataId, soswrapper);

          if(selectedOpt.hasAttribute('data-target')){
            var dataTarget = selectedOpt.getAttribute('data-target');
            showContent(dataTarget, soswrapper);
          }
        }
      };

    });
  }

   /*
    * resetContent()
    * dataId: dataId of selected option
    */
    function resetContent(dataId, soswrapper){
      //  select all contentblocks based on their data-id attribute
      var contentblocks = soswrapper.querySelectorAll('[data-show-on-select-content][data-id="'+ dataId +'"]');

      // Loop through content blocks
      [].forEach.call(contentblocks, function(elem){
        // Set attributes & tabindex
        elem.setAttribute('aria-hidden','true');
        elem.setAttribute('data-show','false');
        elem.setAttribute('tabindex','-1');
      });
    }

   /*
    * showContent()
    * dataTarget: dataTarget of selected option
    */
    function showContent(dataTarget, soswrapper){
      //  select contentblock based on their data-hook attribute
      var contentblock = soswrapper.querySelector('[data-show-on-select-content][data-hook="'+ dataTarget +'"]');

      // Set attributes & tabindex
      contentblock.setAttribute('aria-hidden','false');
      contentblock.setAttribute('data-show','true');
      if(contentblock.hasAttribute('tabindex'))
        contentblock.removeAttribute('tabindex');

    }



})();
