/**
 * Show content in drawer
 */

vl.drawer = {};
vl.drawer.dress;
vl.drawer.dressAll;

(function () {

  var contentVisibleClass = 'js-drawer__content--visible',
    drawerVisibleClass = 'js-drawer--visible',
    closeClass = 'js-drawer__close',
    smallClass = 'js-drawer--small',
    linkedSliderClass = 'js-drawer--linked-slider',
    activeItemClass = 'js-drawer-active-item',
    defaultItemClass = 'js-drawer-default-item';

  vl.drawer.dress = function(drawer) {
    addClass(drawer, 'js-drawer-bound');
    // get all drawer triggers for this specific drawer
    var drawerID = drawer.getAttribute('data-drawer');
    var linkedSlider = hasClass(drawer, linkedSliderClass);
    // add drawer tip
    var tip = document.createElement('div');
    tip.setAttribute('tabindex', -1);
    addClass(tip, 'js-drawer__tip');

    drawer.insertBefore(tip, drawer.firstChild);

    // get every link which should toggle the drawer
    var links = document.querySelectorAll('[data-drawer-id="'+ drawerID +'"]');
    [].forEach.call(links, function (link) {
      // bind click event
      link.addEventListener('click', function (e) {
        e.preventDefault();
        clickLink(link, drawer, tip, true);
      });

      if(hasClass(link, defaultItemClass)) {
        clickLink(link, drawer, tip, false);
      }
    });

    // Close the drawer when clicking close
    var closeLinks = drawer.getElementsByClassName(closeClass);
    [].forEach.call(closeLinks, function (close) {
      close.addEventListener('click', function(e){
        e.preventDefault();
        closeDrawer(drawer);
      });
    });
  };

  vl.drawer.dressAll = function() {

    var elements = document.querySelectorAll('[data-drawer]:not(.js-drawer-bound)');

    [].forEach.call(elements, function (element) {
      vl.drawer.dress(element);
    });
  };

  // Initiate
  vl.drawer.dressAll();

  function clickLink(link, drawer, tip, scroll) {
    var contentID = link.getAttribute('data-drawer-content-id');
    var drawerContent = drawer.querySelectorAll('[data-drawer-content="'+ contentID +'"]')[0];
    // show drawer content
    if(drawerContent) {
      showDrawerContent(drawerContent, drawer, scroll, tip);
    }
    // make link active + place tip
    activateLink(link, tip, drawer);
  }

  function showDrawerContent(drawerContent, drawer, scroll, tip) {
    var visible = hasClass(drawer, drawerVisibleClass);


    if(visible){
      hideDrawerContent(drawer, false);
    }

    // show drawer
    addClass(drawer, drawerVisibleClass);
    // show content
    addClass(drawerContent, contentVisibleClass);

    if(!visible && scroll) {
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      // scroll to drawer
      var scrollposition = (document.body.scrollTop + drawer.getBoundingClientRect().top) - h/4;
      scrollTo(document.body, scrollposition, 300, function(){ tip.focus(); });
    }else if (visible && scroll) { scrollposition = document.body.scrollTop;
      tip.focus();
      document.body.scrollTop = scrollposition;
    }
  }

  function activateLink(link, tip, drawer) {
    addClass(link, activeItemClass);
    var rect = link.getBoundingClientRect(),
      left = rect.width/2,
      drawerRect = drawer.getBoundingClientRect();
    // get link position
    if(hasClass(drawer, smallClass)) {
      left += (rect.left - drawerRect.left);
    }else{
      left += rect.left;
    }

    tip.style.left = left + 'px';
  }

  function closeDrawer(drawer) {
    removeClass(drawer, drawerVisibleClass);
    hideDrawerContent(drawer, true);
  }

  function hideDrawerContent(drawer, scrollto) {
    var drawerID = drawer.getAttribute('data-drawer');
    // Hide other visible drawer contents
    var visibleContent = drawer.getElementsByClassName(contentVisibleClass)[0];
    if(visibleContent) {
      removeClass(visibleContent, contentVisibleClass);
    }
    // make other links unactive
    var link = document.querySelectorAll('[data-drawer-id="'+ drawerID +'"].' + activeItemClass)[0];
    if(link){
      removeClass(link, activeItemClass);
      if(scrollto) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        // scroll to drawer
        var scrollposition = (document.body.scrollTop + link.getBoundingClientRect().top) - h / 4;
        scrollTo(document.body, scrollposition, 300, function () {
          link.focus();
        });
      }
    }
  }
})();
