vl.progressbar = {};
vl.progressbar.updateStep;

(function(){

  vl.progressbar.updateStep = function(progressbar, stepIndex){
    var steps   = progressbar.querySelectorAll('.progress-bar__step'),
        nSteps  = steps.length;

    [].forEach.call(steps, function(step){
      removeClass(step, 'progress-bar__step--active');
      addClass(steps[(stepIndex - 1)], 'progress-bar__step--active');
    });

  };
})();

