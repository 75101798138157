/**
 * Toggle the small class for the bar-navigation if possible
 */
(function () {

  var elements = document.getElementsByClassName('js-bar-navigation');

  function checkBarNavigation() {
    [].forEach.call(elements, function (element) {
      if (vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall')
        addClass(element, 'js-bar-navigation--small');
      else
        removeClass(element, 'js-bar-navigation--small');
    });
  }

  // initiate
  checkBarNavigation();

  window.addEventListener('resize', function() {
    checkBarNavigation();
  });

})();
