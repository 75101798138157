/**
 * Custom form validation extensions using the fiveForms plugin
 */

(function () {
  var forms = document.querySelectorAll('[data-validate-form]');


  // custom validators

  // Validate an IBAN number
  fiveForms.prototype.iban = function (val) {

    var regex = /\s/g,
      replacement = '',
      iban = val.toString();

    iban = iban.replace(regex, replacement);

    return !IBAN.isValid(iban);
  };

  // Validate a date
  fiveForms.prototype.date = function (val) {
    var date = val.toString(),
      pattern = /(^(((0[1-9]|1[0-9]|2[0-8])[\.](0[1-9]|1[012]))|((29|30|31)[\.](0[13578]|1[02]))|((29|30)[\.](0[4,6,9]|11)))[\.](19|[2-9][0-9])\d\d$)|(^29[\.]02[\.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;

    return !(pattern.test(date));
  };

  // Validate a phone number
  fiveForms.prototype.phone = function (val) {
    var phone = val.toString(),
      pattern = /^((\+|00)\s{0,1}32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$|^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/gi;

    return !(pattern.test(phone));
  };

  // Validate a RRN number (Rijksregister)
  fiveForms.prototype.rrn = function (val) {
    var rrn = val.toString(),
      pattern = /^((([0-9]{2})\.){2})([0-9]{2})[\-][0-9]{3}\.[0-9]{2}/gi;

    return !(pattern.test(rrn));
  };

  // Validate an ON number (ondernemingsnummer)
  fiveForms.prototype.onr = function (val) {
    var onr = val.toString(),
      pattern = /^0(\d{3}\.){2}\d{3}/gi;

    return !(pattern.test(onr));
  };

  // Validate a web-uri
  // todo
  fiveForms.prototype.url = function (val) {
    console.log("URL");
  };

  // Validate a number
  fiveForms.prototype.number = function (val) {
    // todo: min & max-attributes checking
    console.log("NUMBER");
  };


  // indicate an error for a field
  function showError(validatedField) {
    var vf = validatedField;
    addClass(vf.obj, vf.obj.getAttribute('data-error-class'));
    // show form error if it is defined
    if (vf.obj.hasAttribute('data-error-message') && vf.obj.hasAttribute('data-error-placeholder') && document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')) {
      (document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')).innerHTML = vf.obj.getAttribute('data-error-message');
    }
    // add error data-attribute
    vf.obj.setAttribute('data-has-error', true);
  }

  // indicate an error for a field
  function clearError(validatedField) {
    var vf = validatedField;
    removeClass(vf.obj, vf.obj.getAttribute('data-error-class'));
    if (vf.obj.hasAttribute('data-error-message') && vf.obj.hasAttribute('data-error-placeholder') && document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')) {
      (document.querySelector('[data-error-id=' + vf.obj.getAttribute('data-error-placeholder') + ']')).innerHTML = '';
    }
    // add error data-attribute
    vf.obj.setAttribute('data-has-error', false);
  }

  // what to do on false submit
  function formError(errors) {
    var errors; //errors is an array of the invalid form fields
    for (var x = 0; x < errors.length; x++) {
      showError(errors[x].obj);
    }
  }


  // initiate validation for the forms
  [].forEach.call(forms, function (form) {              // todo: https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
    // if the form does not have an ID, create one in JS
    if (!form.id) {
      form.setAttribute('id', uniqueId());
    }
    var validate = new fiveForms({
      id: form.id,   // forms always need to have an ID
      onBlur: blurred,
      onError: formError
    });

    validate.addCustomValidator('iban', validate.iban);
    validate.addCustomValidator('date', validate.date);
    validate.addCustomValidator('phone', validate.phone);
    validate.addCustomValidator('rrn', validate.rrn);
    validate.addCustomValidator('onr', validate.onr);
    validate.addCustomValidator('url', validate.url);
    validate.addCustomValidator('number', validate.number);

    function blurred(params) {
      var vf = validate.validateField(validate.getFieldByName(params.srcElement.name)); // get validated field

      if (vf.error) {
        showError(vf);
      } else {
        clearError(vf);
      }
    }

  });
})();
