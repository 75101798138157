/**
 * Initiate the javascript slider "Lory"
 */
(function () {
  var sliderClass = "js-slider",
      sliderFrameClass = "js-slider__frame",
      sliderSlidesClass = "js-slider__slides",
      previousClass = 'js-slider__previous',
      nextClass = 'js-slider__next',
      gridClass = 'grid',
      sliders = document.getElementsByClassName(sliderClass);


  // initiate the slider if all content is loaded
  //document.addEventListener('DOMContentLoaded', function() {
    [].forEach.call(sliders, function (slider) {
      initiateSlider(slider);
    });
  //});

  // Function to initiate the slider
  function initiateSlider(slider) {
    var slides = slider.getElementsByClassName(sliderSlidesClass)[0];
    var sliderFrame = slider.getElementsByClassName(sliderFrameClass)[0];

    var flkty = new Flickity( slides, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true
    });

    // transfer grid class if required
    if(hasClass(slides, gridClass)) {
      removeClass(slides, gridClass);
      addClass(flkty.slider, gridClass);
    }

    // only add buttons if they are usefull
    var target = flkty.selectedCell.target;
    // enable / disable previous
    if(target !== flkty.cells[0].target || target !== flkty.getLastCell().target){
      addButtons();
    }

    function addButtons() {

      // Add navigation buttons to the slider
      // previous
      var previousLink = document.createElement('button');
      previousLink.href = "javascript:;";
      previousLink.text = "previous";
      addClass(previousLink, previousClass);
      slider.insertBefore(previousLink, sliderFrame);
      previousLink.addEventListener('click', function(){
        flkty.previous();
      });

      // next
      var nextLink = document.createElement('button');
      nextLink.href = "javascript:;";
      addClass(nextLink, nextClass);
      insertAfter(nextLink, sliderFrame);
      nextLink.text = "next";
      nextLink.addEventListener('click', function(){
        var target = flkty.selectedCell.target;
        if(target !== flkty.getLastCell().target) {
          flkty.next();
        }
      });

      function disablePreviousNext (flkty) {

        var target = flkty.selectedCell.target;
        // enable / disable previous
        if(target == flkty.cells[0].target){
          addClass(previousLink, previousClass+'--disabled');
        }else{
          removeClass(previousLink, previousClass+'--disabled');
        }
        // enable / disable next
        if(target == flkty.getLastCell().target){
          addClass(nextLink, nextClass+'--disabled');
        }else{
          removeClass(nextLink, nextClass+'--disabled');
        }
      }

      disablePreviousNext(flkty);

      // enable / disable the buttons
      flkty.on( 'cellSelect', function() {
        disablePreviousNext(flkty);
      });
    }
  }
})();
