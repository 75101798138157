/**
 * Dress the sticky elements. Mostly used for side navigation
 * An element is sticky in its closest parent .layout
 * If layout is not present, which should be, we take .region
 */
(function () {

  var absoluteClass = 'js-sticky--absolute';
  var fixedClass = 'js-sticky--fixed';
  var elements = document.getElementsByClassName('js-sticky');
  var latestKnownScrollY = 0,
      ticking = false,
      resized = true;

  // prepare the elements by wrapping in placeholder
  function initiateStickyElement(stickyContent) {

      // set the original position on load + add parent if required
      storeElementData(stickyContent);

      // put placeholder around the sticky content
      var placeholder = document.createElement('div');
      addClass(placeholder, 'js-sticky-placeholder');
      wrap(stickyContent, placeholder);
      // set placeholder height fixed
      placeholder.style.height = placeholder.offsetHeight + "px";
  }

  function storeElementData(stickyContent) {
    removeClass(stickyContent, fixedClass);
    removeClass(stickyContent, absoluteClass);

    // set position properties
    stickyContent.style.top = 'auto';
    stickyContent.style.bottom = 'auto';
    stickyContent.style.left = 'auto';
    stickyContent.style.width = 'auto';

    var rect = stickyContent.getBoundingClientRect();
    var offsetTop = rect.top + window.pageYOffset;
    stickyContent.setAttribute('data-original-top', offsetTop);
    var offsetLeft = rect.left + window.pageXOffset;
    stickyContent.setAttribute('data-original-left', offsetLeft);
    stickyContent.setAttribute('data-original-width', rect.width);
    var stickyContainer = stickyContent.closest('.layout, .region');
    stickyContent.setAttribute('data-max-y', stickyContainer.getBoundingClientRect().bottom + window.pageYOffset);
  }

  function makeSticky(element, offset, scrollPosition) {
    var placeholder = element.parentElement;

    // if the user has scrolled passed the start + offset of the element, make it sticky or update its sticky position
    if(scrollPosition + offset >= element.getAttribute('data-original-top')) {
      var elementHeight = element.offsetHeight;
      // If the element bottom is going outside of the container, make it absolute
      if(element.getAttribute('data-max-y') > elementHeight && element.getAttribute('data-max-y') - scrollPosition - offset < elementHeight) {
        if(!hasClass(element, absoluteClass)){
          // set position properties
          element.style.top = 'auto';
          element.style.bottom = (parseInt(element.getAttribute('data-original-top')) + elementHeight - element.getAttribute('data-max-y')) + 'px'; // calc position t.o.v. placeholder
          element.style.left = '0px';
          element.style.width = '100%';
        }
        addClass(element, absoluteClass);
        removeClass(element, fixedClass);
      } else if(!hasClass(element, fixedClass)) {
        addClass(element, fixedClass);
        removeClass(element, absoluteClass);
        // set position properties
        element.style.top = offset + 'px';
        element.style.bottom = 'auto';
        element.style.left = element.getAttribute('data-original-left') + 'px';
        element.style.width = element.getAttribute('data-original-width') + 'px';
      }
    } else if(hasClass(element, absoluteClass) || hasClass(element, fixedClass)) {
      removeClass(element, fixedClass);
      removeClass(element, absoluteClass);

      // set position properties
      element.style.top = 'auto';
      element.style.bottom = 'auto';
      element.style.left = 'auto';
      element.style.width = 'auto';
    }
  }

  // only add sticky if all content is loaded
  window.addEventListener('load', function() {

    // only on medium or up
    if(vl.breakpoint.value == 'small' || vl.breakpoint.value == 'xsmall'){
      return;
    }

    [].forEach.call(elements, function (stickyContent) {
      initiateStickyElement(stickyContent);
    });

    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', refresh, false);

    onScroll();
  });

  var refresh = function(){
    if(resized) {
      requestAnimationFrame(resetElements);
    }
    resized = false;
  };

  function resetElements () {
    resized = true;

    [].forEach.call(elements, function(el) {
      storeElementData(el);
      onScroll();
    });
  }

  function onScroll() {
    latestKnownScrollY = window.pageYOffset;
    requestTick();
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }


  function update() {
    ticking = false;
    var currentScrollY = latestKnownScrollY;

    [].forEach.call(elements, function(el) {
      makeSticky(el, 75, currentScrollY);
    });
  }

})();

// To do: optimize
// - detect content changes
