/**
 * Create a tag input by data-attribute
 */

vl.dressTaggle;

(function () {

  var elements = document.getElementsByClassName('js-tag-input');



  vl.dressTaggle = function(field) {
    // add div to show tag input
    var tagDiv = document.createElement('div');
    insertAfter(tagDiv, field);
    addClass(tagDiv, 'tag-input-wrapper');
    addClass(tagDiv, 'input-field');
    addClass(tagDiv, 'input-field--block');

    var name = field.name;
    var placeholder = field.placeholder;

    var taggleSettings = {
      containerFocusClass: 'input-field--focus',
      hiddenInputName: name + '[]',
      placeholder: placeholder,
      tabIndex: 0,
      duplicateTagClass: 'taggle--double'
    };

    removeElement(field);

    new Taggle(tagDiv, taggleSettings);
  };



  [].forEach.call(elements, function (element) {
    vl.dressTaggle(element);
  });

})();
