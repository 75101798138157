/**
 *
 * Tabs JS enhancements
 *
**/

// create global vl tabs object & functions
vl.tabs = {};
vl.tabs.dress;

(function () {

  var wrappers            = document.querySelectorAll('[data-tabs]'),
      tabActiveState      = 'tab--active',
      tabsWrapperClass    = 'tabs__wrapper',
      tabClass            = 'tab',
      dataTabList         = '[data-tabs-list]',
      dataTab             = '[data-tab]',
      dataTabPane         = '[data-tab-pane]',
      hiddenTabCTA        = 'tab--cta',
      dataRespToggle      = '[data-tabs-toggle]',
      dataRespToggleLabel = '[data-tabs-toggle] span',
      gI = 0; // Global index used for creating unique tabs when ID isn't present

  vl.tabs.dress = function(tabsContainer) {

    // get all tabs in wrapper
    var tabsList = tabsContainer.querySelector(dataTabList);
    var tabs = tabsContainer.querySelectorAll(dataTab);
    var tabPanes = tabsContainer.querySelectorAll(dataTabPane);
    var tabsWrapper = tabsContainer.querySelector('.' + tabsWrapperClass);

    // Generate responsive toggle | Assign attributes
    var arrToggle = generateResponsiveToggle(tabsContainer, tabsList);
    var toggle = arrToggle[0];
    var toggleLbl = arrToggle[1];

    // Generate tab attributes
    generateTabsAttributes(tabsWrapper, tabsList, tabs, tabPanes, toggleLbl);

    // Loop through tabs in wrapper
    [].forEach.call(tabs, function(tab){
      // detect click event on tab
      tab.addEventListener('click', function(e){
        e.preventDefault();
        if(!hasClass(tab.closest('.' + tabClass), tabActiveState)){
          // Url w. hash
          window.location = '#' + tab.id;

          // Remove active class from all elements
          [].forEach.call(tabs, function(tab){
            var parent = tab.closest('.' + tabClass);
            if(hasClass(parent, tabActiveState)){
              removeClass(parent, tabActiveState);
              tab.setAttribute('aria-selected','false');
            }
          });

          // Get parent (<li> item) of selected tab
          var parent = tab.closest('.' + tabClass);
          addClass(parent, tabActiveState);
          tab.setAttribute('aria-selected','true');

          // Reset active states of all panes
          resetPaneState(tabPanes);

          // toggle active state of all panes
          togglePaneState(tabsContainer, tab.id);

          // Add correct label to (responsive) toggle button
          toggleLbl.innerHTML = tab.innerHTML;

          // Trigger click on close button
          setResponsiveTabsState(tabsList, false, true);
          setToggleState(toggle, false, false);
        }
      });

      // Detect arrow & spacebar usage on tab
      tab.addEventListener('keydown', function(e){
        switch(e.keyCode){
          // Left arrow
          case 37: case 38:
          var index = tab.getAttribute('data-index');
          if(index > 0){
            index--;
            var el = tabsList.querySelector('[data-index="'+ index +'"]');
            el.click();
          }
          break;
          // Right arrow
          case 39: case 40:
          var index = tab.getAttribute('data-index');
          if(index < (tabs.length - 1)){
            index++;
            var el = tabsList.querySelector('[data-index="'+ index++ +'"]');
            el.click();
          }
          break;
        }
      });
    });

    if(tabsContainer.hasAttribute('data-active')){
        var activeTab = tabsContainer.querySelector('#' + tabsContainer.getAttribute('data-active'));
        if(activeTab !== null){
          activeTab.click();
        }else{
          console.warn("data-active attribute doesn't match an ID selector in the list of tabs.");
        }
    }
  }

  /*
  * resetPaneState()
  * tabPanes
  */
  function resetPaneState(tabPanes){
    // Loop through panes and disable the state (attributes & tabindex)
    [].forEach.call(tabPanes, function(elem){
      elem.setAttribute('aria-hidden',true);
      elem.setAttribute('data-show',false);
      elem.setAttribute('tabindex','-1');
    });
  }

  /*
  * togglePaneState()
  * tabsContainer, dataId
  */
  function togglePaneState(tabsContainer, tabId){
    //  select all contentblocks based on their aria attribute
    var pane = tabsContainer.querySelector('[data-tab-pane][aria-labelledby="' + tabId + '"]');
    // Set attributes & tabindex
    pane.setAttribute('aria-hidden','false');
    pane.setAttribute('data-show','true');
    pane.removeAttribute('tabindex');
  }

  /*
  * setToggleState()
  * toggle, data-close, aria-expanded
  */
  function setToggleState(toggle, dataClose, ariaExpanded){
    toggle.setAttribute('data-close', dataClose);
    toggle.setAttribute('aria-expanded', ariaExpanded);
  }

  /*
  * setResponsiveTabsState()
  * tabs, data-show, aria-hidden
  */
  function setResponsiveTabsState(tabsList, dataShow, ariaHidden){
    tabsList.setAttribute('data-show', dataShow);
    tabsList.setAttribute('aria-hidden', ariaHidden);
  }

  /*
  * generateResponsiveToggle()
  * Generate responsive button used in the dropdown on mobile views
  */
  function generateResponsiveToggle(tabsContainer, tabsList){

    var respToggle = document.createElement('button');
    respToggle.setAttribute('type','button');
    respToggle.setAttribute('data-tabs-toggle','');
    respToggle.setAttribute('aria-expanded','false');
    respToggle.setAttribute('aria-label','tabs toggle');
    addClass(respToggle, 'tabs__toggle');
    insertAfter(respToggle, tabsList);

      // Span element w. title
      var respToggleTitle = document.createElement('span');

      if(tabsContainer.hasAttribute('data-tabs-responsive-label'))
        respToggleTitle.innerHTML = tabsContainer.getAttribute('data-tabs-responsive-label');
      else
        respToggleTitle.innerHTML = "Navigatie";

      respToggle.appendChild(respToggleTitle);

    respToggle.addEventListener('click', function(e){
      if(respToggle.getAttribute('data-close') === "true"){
        setResponsiveTabsState(tabsList, false, true);
        setToggleState(respToggle, false, false);
      }else{
        setResponsiveTabsState(tabsList, true, false);
        setToggleState(respToggle, true, true);
      }
    });

    return [respToggle, respToggleTitle];
  }

  /*
  * generateTabsAttributes()
  * Set aria tags, roles and other tags for accessibility
  * Check hash if available and match it to a tab, if present set tab active
  */
  function generateTabsAttributes(tabsWrapper, tabsList, tabs, tabPanes, toggleLbl){

    // Role & data attributes
    tabsList.setAttribute('role', 'tablist');

    [].forEach.call(tabsList.querySelectorAll('.tab'), function(tabListItem){
      tabListItem.setAttribute('role','presentation');
    });

    // Tabs, tabPanes
    var activeElem = false;
    var i = 0;
    [].forEach.call(tabs, function(tab){
      tab.setAttribute('role','tab');

      var tabId = tab.id;
      var panelId = tabPanes[i].id;

      // If none generate tab id
      if(tabId == ""){
        tabId = "tab" + gI + "-" + i;
        tab.setAttribute('id', tabId);
      }

      // If none generate panel id
      if(panelId == ""){
        panelId = "panel" + gI + "-" + i;
        tabPanes[i].setAttribute('id', panelId);
      }

      tab.setAttribute('aria-controls', panelId);
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute('data-index', i);
      tabPanes[i].setAttribute('id', panelId);
      tabPanes[i].setAttribute('role', 'tabpanel');
      tabPanes[i].setAttribute('aria-labelledby', tabId);
      tabPanes[i].setAttribute('aria-hidden', 'true');
      tabPanes[i].setAttribute('data-show', 'false');

      var listItem = tab.closest('.tab');
      // Check if active tab is present
      if(hasClass(listItem, 'tab--active')){
        activeElem = true;
        tab.setAttribute('aria-selected', 'true');
        tabPanes[i].setAttribute('aria-hidden', 'false');
        tabPanes[i].setAttribute('data-show', 'true');
        toggleLbl.innerHTML = tab.innerHTML;
      }

      i++;
      gI++;
    });
  }

  [].forEach.call(wrappers, function (tabsContainer) {
    vl.tabs.dress(tabsContainer);
  });

})();
