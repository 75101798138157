/**
 * Input patterns script
 */

vl.patternInput;

(function () {

  var inputs = document.querySelectorAll('[data-pattern]');

  // predefined patterns
  var patterns = {
    'iban': '{{aa}}{{99}} {{****}} {{9999}} {{999}}{{*}} {{****}} {{****}} {{****}} {{***}}',
    'rrn': '{{99}}.{{99}}.{{99}}-{{999}}.{{99}}',
    'date': '{{99}}.{{99}}.{{9999}}',
    'onr' : '{{9999}}.{{999}}.{{999}}' // ondernemingsnummer, first digit needs to be zero
  };


  // define global function
  vl.patternInput = function(input) {
    // get pattern
    var patternID = input.getAttribute('data-pattern');
    var pattern = patternID;
    if(patternID in patterns){
      pattern = patterns[patternID];
    }

    var formatted = new Formatter(input, {
      'pattern': pattern,
      'persistent': false
    });
  };


  // initiate validation for the inputs

  [].forEach.call(inputs, function(input) {
    vl.patternInput(input);
  });

})();
