/**
 * Push a fixed element by another element in viewport
 * Written to push helpwidget by global footer
 */
(function () {

  var elements = document.getElementsByClassName('js-push'),
      ticking = false;

  function onScroll() {
    requestTick();
  }

  function requestTick() {
    if(!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }

  function update() {
    // reset the tick so we can
    // capture the next onScroll
    ticking = false;

    [].forEach.call(elements, function (element) {
      var pusher = document.getElementsByClassName(element.getAttribute('data-push-by'))[0],
          spacer = parseInt(element.getAttribute('data-push-space')) || 0,
          space = Math.min(0, pusher.getBoundingClientRect().top - element.getBoundingClientRect().bottom - spacer -  Number(element.style.marginBottom.replace(/[^\d\.\-]/g, ''))) * -1 ;

      element.style.marginBottom = space + 'px';
    });
  }

  // add event listener
  if(elements.length) {
    window.addEventListener('scroll', onScroll, false);
  }

})();
