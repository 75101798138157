
/**
 * Set some elements to the same height
 */
(function () {

  function sameHeights (container) {

    var nodeList = container.getElementsByClassName('js-equal-height');
    var elems = [].slice.call(nodeList);

    var tallest = Math.max.apply(Math, elems.map(function(elem, index) {
      elem.style.minHeight = ''; // clean first
      elem.style.height = ''; // clean first
      return elem.offsetHeight;
    }));

    tallest ++;

    elems.forEach(function(elem, index, arr){
      if(elem.hasAttribute('data-equal-height') && elem.getAttribute('data-equal-height') == "height"){
        elem.style.height = tallest + 'px';
      }else{
        elem.style.minHeight = tallest + 'px';
      }
    });

  }

  var resized = true;

  function fixContainers() {
    resized = true;

    // apply per container
    var containers = document.getElementsByClassName('js-equal-height-container');

    [].forEach.call(containers, function (container) {
      sameHeights(container);
    });
  }

  var refresh = function(){
    if(resized) {
      requestAnimationFrame(fixContainers);
    }
    resized = false;
  };
  // only add sticky if all content is loaded
  window.addEventListener('load', function() {
    window.addEventListener('resize', refresh, false);
    refresh();
  });
})();
