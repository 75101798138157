/**
 * Wizard
 **/

vl.wizard = {};
vl.wizard.dress;

(function () {

  vl.wizard.dress = function (wizard, params) {
    var paneWrapper = wizard.querySelector('.wizard__panes'),
        panes       = wizard.querySelectorAll('.wizard__pane'),
        nPanes      = panes.length, // 1e element is "0"
        curPane     = 1,
        ctasNext     = wizard.querySelectorAll('[data-wizard-next]'),
        ctasPrev     = wizard.querySelectorAll('[data-wizard-prev]'),
        progressbar = wizard.querySelector('.progress-bar'),
        pbSteps     = wizard.querySelectorAll('[data-wizard-step]');

    var flkty = new Flickity( paneWrapper, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      draggable: false,
      accessibility: false
    });

    var flkViewport    = wizard.querySelector('.flickity-viewport');

    var k = 1;
    [].forEach.call(pbSteps, function(step){
      step.setAttribute('data-index', k);
      k++;

      step.addEventListener('click', function(e){

        e.preventDefault();
        var index = step.getAttribute('data-index');

        params.callbackFn(curPane, index, returnFn);
        function returnFn(isValid){
          if(isValid){
            curPane = index;
            setProgressbar();
          }
        }
      });
    });

    // Next button
    if(ctasNext){

      [].forEach.call(ctasNext, function(ctaNext){
        ctaNext.addEventListener('click', function(e){
          e.preventDefault();

          if(curPane < nPanes){
            params.callbackFn(curPane, (curPane + 1), returnFn);
            function returnFn(isValid){
              if(isValid){
                curPane++;
                setProgressbar();
              }
            }
          }
        });
      });
    };

    // Prev button
    if(ctasPrev){
      [].forEach.call(ctasPrev, function(ctaPrev){
        ctaPrev.addEventListener('click', function(e){
          e.preventDefault();

          if(curPane > 0){
            params.callbackFn(curPane, (curPane - 1), returnFn);
            function returnFn(isValid){
              if(isValid){
                curPane--;
                setProgressbar();
              }
            }
          }
        });
      });
    };

    function setProgressbar(){
      flkty.select( (curPane -1) );
      vl.progressbar.updateStep(progressbar, curPane);
      // 200 ms delay = animation time
      window.setTimeout(function(){
        setAdaptiveHeight();
      }, 200);
    };

    flkty.on( 'cellSelect', function() {
      setPaneVisibility();
    });

    // Check pane visibility and set a class "not-selected" to hide it
    function setPaneVisibility(){
      window.setTimeout(function(){
        [].forEach.call(panes, function(pane){
          removeClass(pane, 'not-selected');

          if(!hasClass(pane, 'is-selected')){
            addClass(pane, 'not-selected');
          }
        });
      }, 200);
    };
    setPaneVisibility();

    function setAdaptiveHeight(){
      // Adaptiveheight is not supported in v1 of flickity, so simulate it
      var activePane = wizard.querySelector('.is-selected');
      var activePaneHeight = activePane.offsetHeight;
      flkViewport.style.height = activePaneHeight + "px";
    };
    setAdaptiveHeight();
  };

})();
