/**
 *
 * Drilldown navigation
 *
**/

vl.drilldown = {};
vl.drilldown.dress;

(function () {

  var elements = document.querySelectorAll('[data-drilldown]'),
      dataColumn = '[data-drilldown-column]',
      column = 'drilldown__column',
      subcolumn = 'drilldown__subcolumn',
      subcolumnHeader = 'drilldown__subcolumn__header',
      activeSubcolumn = 'drilldown__subcolumn--active',
      listWrapper = 'drilldown__list-wrapper',
      item = 'drilldown__item',
      callToAction = 'drilldown__item__cta',
      activeCallToAction = 'drilldown__item__cta--active';

  vl.drilldown.dress = function(drilldown) {

    // Set search
    var hasSearch = false;
    if(drilldown.getAttribute('data-search') !== "false"){
      hasSearch = true;
    }

    // Generate all extra attributes
    generateDrilldownAttributes(drilldown, hasSearch);

    // Set variables
    var callToActions       = drilldown.querySelectorAll('[data-drilldown-cta]');
    var backCallToActions   = drilldown.querySelectorAll('[data-drilldown-back]');
    var coverCallToActions  = drilldown.querySelectorAll('[data-drilldown-cover]');
    var inputFields         = drilldown.querySelectorAll('[data-input]');
    var columns             = parseInt(drilldown.getAttribute('data-drilldown-columns'));

    /*
    * loop General CTA's
    */
    [].forEach.call(callToActions, function (cta) {
      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get state of clicked element
        var isActive = hasClass(cta, activeCallToAction);

        // Get subcolumn of clicked element
        var parentItem = cta.closest('.' + item);
        var child = parentItem.querySelector('.' + subcolumn);

        // Get parent column of clicked element
        var parentColumn = cta.closest(dataColumn);
        removeActiveCtas(parentColumn);
        removeActiveColumns(parentColumn);

        // Toggle the active class based on its active state
        if(!isActive) {
          dressSubColumn(this, child);
          addClass(cta, activeCallToAction);
        }else{
          killSubColumn(this, child);
          removeClass(cta, activeCallToAction);
        }
        triggerResize();

      });
    });

    /*
    * loop Back Buttons
    */
    [].forEach.call(backCallToActions, function (cta) {
      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get subcolumn
        var col = cta.closest('.' + subcolumn);
        killSubColumn(this, col);

        // Get parent's active CTA and remove the active state
        var parent = col.closest('.' + item);
        var activeCta = parent.querySelector('.' + callToAction);
        removeClass(activeCta, activeCallToAction);

        triggerResize();

        return false;
      });
    });

    /*
    * loop Cover CTA's
    */
    [].forEach.call(coverCallToActions, function (cta) {

      // bind click event
      cta.addEventListener('click', function (e) {
        e.preventDefault();

        // Get subcolumn
        var depth = parseInt(cta.getAttribute('data-drilldown-cover'));
        var col = document.querySelector('[data-drilldown-depth="' + (depth +1) + '"]'); // Plus 1 to kill the next column, not the current one

        killSubColumn(this, col);

        // Get parent's active CTA and remove the active state
        var parent = col.closest('.' + item);
        var activeCta = parent.querySelector('.' + callToAction);
        removeClass(activeCta, activeCallToAction);
        triggerResize();
      });
    });

    /*
    * loop data inputs and add search functionality
    */
    [].forEach.call(inputFields, function(inputField){

      var subcol = inputField.closest(dataColumn);
      var depth = subcol.getAttribute('data-drilldown-depth');
      var items = subcol.querySelectorAll('[data-drilldown-index-depth="'+depth+'"]');

      inputField.addEventListener('keyup', function(e){

        if(e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 27 && e.keyCode !== 30 && e.keyCode !== 13){
          var searchString = inputField.value.toLowerCase();
          var k = 0, options = [];
          [].forEach.call(items, function(item){
            var term = stripTags(item.innerHTML.toLowerCase());

            item.removeAttribute('data-index');
            item.removeAttribute('data-focus');
            item.setAttribute('aria-hidden', 'true');

            if(term.indexOf(searchString ) > -1){
              item.setAttribute('data-index', k);
              if(k == 0){
                item.setAttribute('data-focus', 'true');
              }

              options.push(item);
              item.setAttribute('aria-hidden', 'false');
              k++;
            }
          });

          // No results found
          if(options.length <= 0){
            var el = drilldown.querySelector('.drilldown__empty');
            if(el == null){
              var noResultsFoundEl = document.createElement("div");
                  addClass(noResultsFoundEl, 'drilldown__empty');

                  var msg = drilldown.getAttribute('data-search-empty');
                  if(msg == null){
                    msg = "Geen resultaten gevonden";
                  }
                  noResultsFoundEl.innerHTML = msg;

              var wrapper = subcol.querySelector('.' + listWrapper);
              wrapper.appendChild(noResultsFoundEl);
            }
          }else{
            var el = drilldown.querySelector('.drilldown__empty');
            if(el !== null){
              removeElement(el);
            }
          }
        }
      });
    });

    /*
    * dressSubColumn()
    * - Initiates a subcolumn
    * - Sets current columns
    */
    var dressSubColumn = function(cta, subcol){
      // Increase count of columns
      var curCols = parseInt(subcol.getAttribute('data-drilldown-depth'));
      drilldown.setAttribute('data-drilldown-columns', curCols);

      // Add active state of subcolumn
      addClass(subcol, activeSubcolumn);
      subcol.setAttribute('aria-expanded', true);

      // Set focus on first element and (optional) search
      setFocus(subcol);
    }

    /*
    * killSubColumn()
    * Hides a subcolumn
    * - Sets current columns
    */
    var killSubColumn = function(cta, subcol){
      // Decrease count of columns
      var curCols = parseInt(subcol.getAttribute('data-drilldown-depth')) - 1;
      drilldown.setAttribute('data-drilldown-columns', curCols);
      // Remove active state of subcolumn
      removeClass(subcol, activeSubcolumn);
      subcol.setAttribute('aria-expanded', false);

      var activeEls = subcol.querySelectorAll('[data-focus="true"]');
      [].forEach.call(activeEls, function(el){
        el.removeAttribute('data-focus');
      });

      // Set focus on new active col
      var activeCol = subcol.closest('[data-drilldown-column][data-drilldown-depth="'+curCols+'"]');
      setFocus(activeCol);
    }

    /*
    * Drilldown keydown events
    * Progressive enhancement for Drilldown
    * - Arrow keys (up/down) = go up/down the ladder
    * - Escape key = Close current column and go back one step
    * - Enter + Spacebar = Click event on element
    */
    drilldown.addEventListener('keydown', function(e){

      switch(e.keyCode){
        // Escape up
        case 27:
          e.preventDefault();
          var activeColIndex = drilldown.getAttribute('data-drilldown-columns');
          var activeItem = drilldown.querySelector('[data-drilldown-depth="' + activeColIndex + '"] [data-focus="true"]');
          if(activeItem !== null){
            var col = activeItem.closest('.drilldown__subcolumn');
            if(col !== null){
              var closeButton = col.querySelector('[data-drilldown-back]');
              closeButton.click();
            }
          }
        break;

        // Arrow up
        case 38:
          e.preventDefault();
          moveFocus("up");
        break;

        // Arrow down
        case 40:
          e.preventDefault();
          moveFocus("down");
        break;

        // Enter / Spacebar
        case 13:
          var activeColIndex = drilldown.getAttribute('data-drilldown-columns');
          var activeItem = drilldown.querySelector('[data-drilldown-depth="' + activeColIndex + '"] [data-focus="true"]');
          if(activeItem !== null){
            e.preventDefault();
            activeItem.click();
          }
        break;
      }
    });

    var setFocus = function(col){
      // Set timeout necessary to wait for end of animation
      window.setTimeout(function(){
        var activeEl = col.querySelector('[data-focus="true"]');
        if(activeEl == null){
          col.querySelector('[data-index="0"]').focus();
          col.querySelector('[data-index="0"]').setAttribute('data-focus', 'true');
        }else{
          activeEl.focus();
        }

        if(hasClass(col, 'drilldown__subcolumn') && hasSearch){
          col.querySelector('[data-input]').focus();
        }
      }, 200);
    }

    var moveFocus = function(direction){
      var activeColIndex = drilldown.getAttribute('data-drilldown-columns');

      var activeItem = drilldown.querySelector('[data-drilldown-depth="' + activeColIndex + '"] [data-focus="true"]');
      if(activeItem !== null){

        var curIndex = activeItem.getAttribute('data-index');
        var colItems = drilldown.querySelectorAll('[data-drilldown-index-depth="'+ activeColIndex +'"][aria-hidden="false"]');

        switch(direction){
          case "up":
            if(curIndex > 0){
              curIndex --;
              _setActiveStateAndFocus();
            }
          break;

          case "down":
          //  Checken als cuindex kleiner is dan aantal items in de kolom
            if(curIndex < (colItems.length - 1)){
              curIndex ++;
              _setActiveStateAndFocus();
            }
          break;
        }
      }


      function _setActiveStateAndFocus(){
        activeItem.removeAttribute('data-focus');
        var newItem = drilldown.querySelector('[data-drilldown-index-depth="' + activeColIndex + '"][data-index="' + curIndex + '"]');
        newItem.focus();
        if(hasSearch){
          var searchInput = drilldown.querySelector('[data-drilldown-depth="' + activeColIndex + '"] [data-input]');
          searchInput.focus();
        }
        newItem.setAttribute('data-focus','true');
      }
    }

    /*
    * removeActiveCtas()
    * Removes all active CTA classes from CTA's starting from parentNode downwards
    */
    var removeActiveCtas = function(parentColumn){
      var activeCtas = parentColumn.querySelectorAll('.' + activeCallToAction);
      if(activeCtas !== null){
        [].forEach.call(activeCtas, function(e){
          removeClass(e, activeCallToAction);
        });
      }
    }

    /*
    * removeActiveColumns()
    * Removes all active Column classes from columns starting from parentNode downwards
    */
    var removeActiveColumns = function(parentColumn){
      var els = parentColumn.querySelectorAll(dataColumn);

      if(els !== null){
        [].forEach.call(els, function(e){
          if(hasClass(e, activeSubcolumn)){
            removeClass(e, activeSubcolumn);
          }
        });
      }
    }

    /*
    * triggerResize()
    * Invokes a resize event so equalheight is recalculated
    */
    var triggerResize = function(){
        var cols = drilldown.querySelectorAll('.' + subcolumn);
        var maincol = drilldown.querySelector('.' + column);
        var maxHeight = "";
        maincol.style.height = "";

        // Get max height
        [].forEach.call(cols, function(col){
          // Variables
          var wrapper = col.querySelector('.' + listWrapper);
          var header = col.querySelector('.' + subcolumnHeader);
          // Reset all
          wrapper.style.height = "";
          col.style.height = "";
          // Get height
          var colHeight = col.offsetHeight;
          // Check if height exceeds windowheight
          if(colHeight > maxHeight){
            if(colHeight > (window.innerHeight/1.5)){
              maxHeight = window.innerHeight/1.5;
            }else{
              maxHeight = colHeight;
            }
          }
          // Apply height to wrapper
          wrapper.style.height = (maxHeight - header.offsetHeight) + "px";
        });

        // Apply max height on all subcols
        [].forEach.call(cols, function(col){
          col.style.height = maxHeight + "px";
        });
        // Apply max height on first col
        maincol.style.height = maxHeight + "px";
    }

    /*
    * generateDrilldownAttributes();
    * Generate extra attributes through JS
    */
    function generateDrilldownAttributes(drilldown, hasSearch){

      // Drilldown
      drilldown.setAttribute('data-drilldown-columns', '1');

      // Drilldown cols
      var cols = drilldown.querySelectorAll('.drilldown__column, .drilldown__subcolumn');

      var first = false;
      var k = 0;
      [].forEach.call(cols, function(col){
        col.setAttribute('data-drilldown-column','');
        var depth = col.getAttribute('data-drilldown-depth');

        // Generate cover (go back) cta
        _generateCoverCta(col, depth);

        // Generate back cta (arrow)
        _generateBackCta(col);

        // Generate search
        if(hasClass(col, 'drilldown__subcolumn') && hasSearch){
          _generateSearch(drilldown, col);
        }

        // Generate data indexes on CTA's
        var k2 = 0;
        var list = col.querySelector('.drilldown__list');
        list.setAttribute('id', 'drilldown__list-' + k);

        [].forEach.call(list.querySelectorAll('#drilldown__list-' + k + ' > .drilldown__item > .drilldown__item__cta'), function(cta){
          cta.setAttribute('data-drilldown-index-depth', depth);
          cta.setAttribute('data-index', k2);
          cta.setAttribute('aria-hidden', 'false');
          k2++;
        });

        k2++;
      });


      function _generateCoverCta(col, depth){
        var coverCTA = document.createElement("button");
          coverCTA.setAttribute("type","button");
          coverCTA.setAttribute("data-drilldown-cover",depth);
          addClass(coverCTA, "drilldown__cta-cover");

          var coverCTASpan = document.createElement("span");
              coverCTASpan.innerHTML = "Terug naar niveau " + depth;

          coverCTA.appendChild(coverCTASpan);
        col.appendChild(coverCTA);
      }

      function _generateBackCta(col){
        var backCTA = document.createElement("button");
          backCTA.setAttribute("type","button");
          backCTA.setAttribute("data-drilldown-back","");
          addClass(backCTA, "drilldown__item__cta--back");

          var backCTASpan = document.createElement("span");
              backCTASpan.innerHTML = "Sluit submenu";

          backCTA.appendChild(backCTASpan);

        var header = col.querySelector('.' + subcolumnHeader);
        if(header !== null)
            header.appendChild(backCTA);
      }

      function _generateSearch(drilldown, col){

        var header = col.querySelector('.' + subcolumnHeader);
        if(header !== null){
          var placeholder = drilldown.getAttribute('data-search-ph');
          if(placeholder == null){
            placeholder = "Zoek";
          }
          var inputWrapper = document.createElement('div');
            addClass(inputWrapper, 'drilldown__subcolumn__search');

            var input = document.createElement('input');
                addClass(input, 'drilldown__subcolumn__search__input');
                addClass(input, 'input-field');
                addClass(input, 'input-field--block');
                input.setAttribute('type', 'text');
                input.setAttribute('aria-label', placeholder);
                input.setAttribute('placeholder', placeholder);
                input.setAttribute('data-input', '');

                inputWrapper.appendChild(input);

            header.appendChild(inputWrapper);
        }else{
          // Generate warning
          console.warn("Search warning in subcolumn " + col.getAttribute('data-drilldown-depth') + ": " + "In order to apply the search bar make sure a subcolumn header (.drilldown__subcolumn__header) is present inside the subcolumn.");
        }
      }
    }
  };

  [].forEach.call(elements, function (drilldown) {
    vl.drilldown.dress(drilldown);
  });

})();
