var vl = {};

(function() {
  "use strict";
  /**
   * Add js class to the HTML
   */
  addClass(document.querySelectorAll('html')[0], 'js');
  removeClass(document.querySelectorAll('html')[0], 'no-js');

  /**
   * Add eventlisteners to window
   */
  window.addEventListener('resize', windowResize);

  // transfer css breakpoints to JS
  vl.breakpoint = {};
  vl.breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
  };
  vl.breakpoint.refreshValue();


  /**
   * Call the initial functions which do not need all content to be loaded
   */
  // dress the accordions / popups
  setAccordions();
  setPopups();

  /**
   * Call the initial functions when page is loaded
   */
  window.addEventListener('load', function() {
    // Call infographic text sizing
    infographicFitText();
    //Shows content when checkbox is checked
    showContentOnChange();
    // highlight double input labels
    highlightDoubleInputs();

  });

  /**
   * all functions to do on window resize
   */
  function windowResize() {
    infographicFitText();
    vl.breakpoint.refreshValue();
  }

  /**
   * Show content when a linked radio / checkbox is checkednpm
   */
  function showContentOnChange(){
    var elements = document.querySelectorAll('[data-show-checked-target]');

    [].forEach.call(elements, function(element) {
      // init without event
      showChecked(element);

      // if the target is a radio-button, trigger the change event on the other radio-buttons in the group
      if(element.type === "radio"){
        var relatedRadios = document.getElementsByName(element.name);
        [].forEach.call(relatedRadios, function(radio) {

          if(!radio.hasAttribute('data-show-bound')) {
            radio.addEventListener('change', function (e) {
              var relatedRadios = document.getElementsByName(e.target.name);
              [].forEach.call(relatedRadios, function (radio) {
                if(radio.hasAttribute('data-show-checked-target')) {
                  showChecked(radio);
                }
              });
            });
            radio.setAttribute('data-show-bound', true);
          }
        });
      }else {
        element.addEventListener('change', function (e) {
          e.preventDefault();
          showChecked(e.target);
        }, false);
      }

      function showChecked (el){
        var target = document.querySelectorAll('[data-show-checked-trigger="' + el.getAttribute('data-show-checked-target') + '"]')[0];

        if(el.checked){
            addClass(target, 'js-show-checked--open');
        }else{
            removeClass(target, 'js-show-checked--open');
        }
      }

    });
  }

  /**
   * Double input, highlight the active top-label
   */

  // create global function
  vl.dressDoubleInput = function(element) {
    var activeClass = 'js-double-input-top-label--active';
    // add focus event to the input fields
    var inputs = element.querySelectorAll('[data-double-input-label-highlight]');
    // add events
    [].forEach.call(inputs, function(input) {
      input.addEventListener('focus', function() {
        var labelID = input.getAttribute('data-double-input-label-highlight');
        var label = element.querySelector('[data-double-input-label="'+labelID+'"]');
        addClass(label, activeClass);
      });
      input.addEventListener('blur', function() {
        var labelID = input.getAttribute('data-double-input-label-highlight');
        var label = element.querySelector('[data-double-input-label="'+labelID+'"]');
        removeClass(label, activeClass);
      });
    });
  };

  function highlightDoubleInputs() {
    // get all elements with a data-attribute data-clamp
    var elements = document.querySelectorAll('.js-double-input');
    // apply highlighting for all fields
    [].forEach.call(elements, function(element) {
      vl.dressDoubleInput(element);
    });
  }


  /**
   * Change the font-size of the value of an infographic to fit the container
   */
  function infographicFitText(){
    // get all infographic elements
    var elements = document.querySelectorAll('.js-infographic__value');
    // set the text-size for the value of each infographic
    [].forEach.call(elements, function(element) {
      // Add dots in large numbers.
      var number = element.innerHTML;
      number = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      element.innerHTML = number;
      textFit(element, {maxFontSize: 50});
    });
  }

  function setAccordions() {
    // get all accordion toggle elements
    var elements = document.querySelectorAll('.js-accordion__toggle');
    // add functionality to the accordions
    [].forEach.call(elements, function(element) {
      element.addEventListener('click', function(e) {
        e.preventDefault();
        // get the closest accordion parent
        var accordion = this.closest('.js-accordion');
        toggleClass(accordion, 'js-accordion--open');
        // check if the text should change when opening / closing
        var toggleText = element.querySelector(".js-accordion__toggle__text");
        if(toggleText && toggleText.hasAttribute('data-accordion-open-text')  && toggleText.hasAttribute('data-accordion-close-text')){
          if(hasClass(accordion, "js-accordion--open")) {
            toggleText.innerHTML = toggleText.getAttribute('data-accordion-close-text');
          }else {
            toggleText.innerHTML = toggleText.getAttribute('data-accordion-open-text');
          }
        }
      });
    });
  }

  function setPopups() {
    // get all popup toggle elements
    var elements = document.querySelectorAll('.js-popup__toggle');
    var id = 0;
    // add functionality to the popup
    [].forEach.call(elements, function(element) {
      // add aria tags
      element.setAttribute('aria-expanded', false);
      element.setAttribute('aria-controls', "popup-"+id);
      // get the closest popup parent
      var popup = element.closest('.js-popup');
      // add accessibility
      popup.setAttribute('id', "popup-"+id);

      element.addEventListener('click', function(e) {
        e.preventDefault();
        if(!hasClass(popup, 'js-popup--open')) {
          // close other popups
          fireEvent(document, 'click');
          addClass(popup, 'js-popup--open');
          element.setAttribute('aria-expanded', true);
        }else {
          // toggle class
          removeClass(popup, 'js-popup--open');
          element.setAttribute('aria-expanded', false);
        }
      });

      popup.addEventListener('click', function(e){
        e.stopPropagation();
      });

      document.addEventListener('click', function(){
        removeClass(popup, 'js-popup--open');
        element.setAttribute('aria-expanded', false);
      });

      document.addEventListener('keyup', function(event) {
        if (event.ctrlKey && event.which === 72) {
          removeClass(popup, 'js-popup--open');
          element.setAttribute('aria-expanded', false);
        }
      });
      id++;
    });
  }
})();
