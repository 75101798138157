/**
 * Bind the dynamic input
 */
(function () {

  var elements = document.getElementsByClassName('js-dynamic-label');

  [].forEach.call(elements, function (element) {
    // get all variables
    var label = element.getElementsByClassName('js-dynamic-label__value')[0],
        value = label.innerHTML,
        input = element.getElementsByClassName('js-dynamic-label__field')[0],
        toggle = element.getElementsByClassName('js-dynamic-label__toggle')[0];

    // set input value
    input.value = value;

    // add toggle event on clicking the icon
    toggle.addEventListener('click', toggleInput);
    // toggle on esc / enter in the input field
    input.addEventListener('keydown', function(e){
      if (e.keyCode === 13) {
        e.preventDefault();
        // move focus to
        fireEvent(input, 'blur');
        // check if it is valid
        if(input.getAttribute('data-has-error') !== "true"){
          // On enter transfer the value
          label.innerHTML = this.value;
          toggleInput();
        }
      }
      // on esc
      if(e.keyCode === 27) {
        e.preventDefault();
        this.value = label.innerHTML;
        toggleInput();
      }
    });

    // function to activate the input
    function toggleInput() {
      var activeClass = 'dynamic-label--active';

      if(hasClass(element, activeClass)){
        removeClass(element, activeClass);
      }else{
        addClass(element, activeClass);
        input.select();
      }
    }
  });

})();
